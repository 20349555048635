import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Polygon } from 'geojson';
import { AreaSearchResult, PlaceSearchResult, POISearchResult, StreetAddressResult } from '@alltrails/search/types/algoliaResultTypes';
import ExploreActionType from '@alltrails/analytics/enums/ExploreActionType';
import type BoundingBox from '../types/BoundingBox';
import type ExploreServiceFilters from '../types/ExploreServiceFilters';
import type ExploreServiceResponse from '../types/ExploreServiceResponse';
import type ExploreServiceSort from '../types/ExploreServiceSort';
import type TrailCompletion from '../types/TrailCompletion';
import { defaultExploreServiceSort } from '../utils/constants';
import DistanceAway from '../types/DistanceAway';
import AdminExploreFilters from '../types/AdminExploreFilters';
import { exploreServiceApi } from './exploreServiceApi';

export type LocationResult = AreaSearchResult | PlaceSearchResult | POISearchResult | StreetAddressResult;
export type ResultsInfo = { adminFilterCount?: number; filterCount: number; isLoading: boolean; summary?: ExploreServiceResponse<any>['summary'] };

export type ExploreServiceState = {
  adminFilters: AdminExploreFilters;
  boundingBox: BoundingBox | undefined;
  distanceAway: DistanceAway | undefined;
  distanceAwayIsochrone: Polygon | undefined;
  distanceAwayLoading?: boolean;
  // Just used to keep track of the most recent action type for logging analytics events.
  // This isn't a perfect solution but its the best we can do without a major overhaul to the explore-service integration.
  exploreActionType?: ExploreActionType;
  filters: ExploreServiceFilters;
  locationResult?: LocationResult;
  mapRotation: number;
  resultsInfo?: ResultsInfo;
  sort: ExploreServiceSort;
  trailCompletion: TrailCompletion[];
};

export const initialState: ExploreServiceState = {
  adminFilters: {},
  boundingBox: undefined,
  distanceAway: undefined,
  distanceAwayIsochrone: undefined,
  distanceAwayLoading: false,
  exploreActionType: undefined,
  filters: {},
  mapRotation: 0,
  resultsInfo: undefined,
  sort: defaultExploreServiceSort,
  trailCompletion: []
};

export const exploreServiceSlice = createSlice({
  name: 'exploreService',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<{ persistKeys?: (keyof ExploreServiceState)[]; initialState?: Partial<ExploreServiceState> }>) => {
      const defaultState: ExploreServiceState = { ...initialState, ...(action.payload.initialState || {}) };
      action.payload.persistKeys?.forEach(key => {
        const persistValue = state[key];
        (defaultState[key] as typeof persistValue) = persistValue;
      });

      return defaultState;
    },
    updateAdminFilters: (state, action: PayloadAction<AdminExploreFilters>) => {
      state.adminFilters = action.payload;
    },
    updateBoundingBox: (state, action: PayloadAction<BoundingBox | undefined>) => {
      state.boundingBox = action.payload;
    },
    updateDistanceAway: (state, action: PayloadAction<DistanceAway | undefined>) => {
      state.distanceAway = action.payload;
    },
    updateDistanceAwayIsochrone: (state, action: PayloadAction<Polygon | undefined>) => {
      state.distanceAwayIsochrone = action.payload;
    },
    updateDistanceAwayLoading: (state, action: PayloadAction<boolean>) => {
      state.distanceAwayLoading = action.payload;
    },
    updateExploreActionType: (state, action: PayloadAction<ExploreActionType>) => {
      state.exploreActionType = action.payload;
    },
    updateFilters: (state, action: PayloadAction<ExploreServiceFilters>) => {
      state.filters = action.payload;
    },
    updateLocationResult: (state, action: PayloadAction<LocationResult | undefined>) => {
      state.locationResult = action.payload;
    },
    updateMapRotation: (state, action: PayloadAction<number>) => {
      state.mapRotation = action.payload;
    },
    updateResultsInfo: (state, action: PayloadAction<ResultsInfo>) => {
      state.resultsInfo = action.payload;
    },
    updateSort: (state, action: PayloadAction<ExploreServiceSort>) => {
      state.sort = action.payload;
    },
    updateTrailCompletion: (state, action: PayloadAction<TrailCompletion[]>) => {
      state.trailCompletion = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(exploreServiceApi.endpoints.getResultsBoundingBox.matchFulfilled, (state, { payload }) => {
      if (payload.boundingBox) {
        state.boundingBox = payload.boundingBox;
        state.mapRotation = 0;
      }
    });
  }
});

export const {
  resetState,
  updateAdminFilters,
  updateBoundingBox,
  updateDistanceAway,
  updateDistanceAwayIsochrone,
  updateDistanceAwayLoading,
  updateExploreActionType,
  updateFilters,
  updateLocationResult,
  updateMapRotation,
  updateResultsInfo,
  updateSort,
  updateTrailCompletion
} = exploreServiceSlice.actions;

export const exploreServiceReducer = exploreServiceSlice.reducer;
