'use client';

import logShowMoreClicked from '@alltrails/analytics/events/logShowMoreClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import ShowMoreLocation from '@alltrails/analytics/enums/ShowMoreLocation';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import { useMediaQuery } from '@alltrails/core';
import PlusCta from '../PlusCta';
import styles from './Description.module.scss';
import useShouldRenderPlusCta from '@/hooks/useShouldRenderPlusCta';

type Props = {
  overview: string;
  description?: string | null;
  isBot: boolean;
  pageType?: PageType;
  seoPoiDescriptionTest?: boolean;
};

const Description = ({ overview, description, isBot, pageType, seoPoiDescriptionTest }: Props) => {
  const content = seoPoiDescriptionTest && description ? description : overview;
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MD_SIZE}px)`);
  const defaultMaxLines = isMobile ? 2 : 3;

  const renderPlusCta = useShouldRenderPlusCta();

  return (
    <>
      {isBot ? (
        <div className={styles.locationDescription}>{content}</div>
      ) : (
        <ShowMoreText
          text={content}
          className={styles.locationDescription}
          maxLines={defaultMaxLines}
          testIdPrefix="location-description"
          useShortText
          onClick={() => {
            if (pageType) logShowMoreClicked({ page_type: pageType, show_more_location: ShowMoreLocation.Overview });
          }}
        />
      )}
      {renderPlusCta && <PlusCta />}
      <div className={styles.divider} />
    </>
  );
};

export default Description;
